<template>
  <b-modal
    ref="billUploadModal"
    title="Bill Upload"
  >
    <p class="my-4">
      Hello from modal!
    </p>
  </b-modal>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      actualForm: {
      },
    }
  },
  computed: {
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
  },
  mounted() {

  },
  methods: {

  },
}
</script>
