<template>
  <div>
    <b-card
      title="Images"
      no-body
    >
      <b-alert
        v-if="multiple"
        class="p-2"
        :show="selectedImages.length>0 && selectedImages.length < maxFiles"
        variant="info"
      >
        You may select {{ maxFiles - selectedImages.length }} more files.
      </b-alert>
      <b-alert
        v-if="multiple"
        class="p-2"
        :show="selectedImages.length === maxFiles"
      >
        You may only select {{ maxFiles }} files. Click on the Select Image button to edit your selection.
      </b-alert>
      <div
        class="position-relative d-flex flex-wrap bg-light p-1"
        style="min-height: 200px"
      >
        <template v-if="!multiple">
          <template v-if="checkBase64Validity(selectedImages)">
            <b-img
              v-if="selectedImages"
              class="img-fluid"
              :src="`data:image/png;base64, ${selectedImages}`"
            />
          </template>
          <template v-if="!checkBase64Validity(selectedImages)">

            <lp-base-image
              v-if="selectedImages && get_url_extension(selectedImages) !== 'pdf'"
              clickable
              :image-public-url="selectedImages"
              @trigger-clickable="previewImage(selectedImages)"
            />
            <l-p-base-file-viewer
              v-if="get_url_extension(selectedImages) === 'pdf'"
              clickable
              :dimensions="{width: 100, height: 100}"
              file-extension="pdf"
              :image-public-url="selectedImages"
              @file-viewer-clicked="downloadItem(selectedImages)"
            />
          </template>

        </template>
        <template v-if="multiple">
          <template v-for="(image, index) in selectedImages">
            <lp-base-image
              v-if="get_url_extension(image) !== 'pdf'"
              :key="`bimg-${index}`"
              :image-public-url="image"
            />
            <l-p-base-file-viewer
              v-if="get_url_extension(image) === 'pdf'"
              :key="`pdf-${index}`"
              file-extension="pdf"
              :image-public-url="image"
              @file-viewer-clicked="downloadItem(image)"
            />
          </template>
        </template>

        <div
          class="position-absolute"
          style="right: 0; bottom: 0"
          @click="openModal"
        >
          <b-button
            size="sm"
            variant="secondary"
            class="m-1"
          >
            Select From Gallery
          </b-button>
        </div>
      </div>
    </b-card>

    <b-modal
      ref="imagePreviewModal"
      title="Preview Image"
      ok-only
      :size="modalSize"
    >
      <b-img
        :src="selectedForPreview"
        class="img-fluid"
      />
    </b-modal>

    <!--    modal-->
    <lp-base-gallery
      :allowed-file-types="allowedFileTypes"
      :field-name="formVar"
      :max-files="maxFiles"
      :multiple="multiple"
      title="Uploaded Files"
      :ref-id="`uploadFormModal-${formVar}`"
      :modal-id="`uploadFormModal-${formVar}`"
      @image-selected="processForm(formVar, $event)"
    />
  </div>
</template>

<script>
import axios from 'axios'
import LpBaseImage from '@/leanpay-components/LPBaseImage.vue'
import LpBaseGallery from '@/leanpay-components/LPBaseGallery.vue'
import LPBaseFileViewer from '@/leanpay-components/LPBaseFileViewer.vue'
import modal from '@/views/admin/billUpload/modal.vue'

export default {
  components: {
    LPBaseFileViewer,
    LpBaseGallery,
    LpBaseImage,
  },
  props: {
    allowedFileTypes: {
      type: String,
      default: 'image/*',
    },
    formVar: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
    selectedImages: {
      required: true,
    },
  },
  data() {
    return {
      modalSize: 'lg',
      selectedForPreview: null,
    }
  },
  computed: {
    imageForUpload() {
      const listOfImages = this.$store.getters['mediaStore/getSelectedImageForUpload']
      return listOfImages
    },
  },
  methods: {
    previewImage(href) {
      this.selectedForPreview = href
      this.$refs.imagePreviewModal.show()
    },
    downloadItem(href) {
      const link = document.createElement('a')
      const filename = href.substring(href.lastIndexOf('/') + 1)
      link.href = href
      link.download = 'filename'
      link.setAttribute('download', filename) // any other extension
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
      link.remove()
      URL.revokeObjectURL(link.href)
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split('.').pop().trim()
    },
    checkBase64Validity(str) {
      const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
      return base64regex.test(str)
    },
    reduceArray(arr) {
      if (arr.length === 1) {
        return arr[0]
      } return ''
    },
    processForm(field, $event) {
      if (this.multiple) {
        this.$emit('image-selected', $event)
      } else { this.$emit('image-selected', $event[0]) }
    },
    openModal() {
      this.$bvModal.show(`uploadFormModal-${this.formVar}`)
    },
  },
}
</script>
