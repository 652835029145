<template>
  <img
    :file-extension="fileExtension"
    :class="[extraClass, {'cursor-pointer': clickable}]"
    class="file-thumbnail"
    :type="type"
    :width="`${dimensions.width}+'px'`"
    :height="`${dimensions.height}+'px'`"
    :dimensions="dimensions"
    :src="imageSource"
    :alt="description"
    :gravity="gravity"
    @click="imageClicked"
  >
</template>

<script>
const IMAGE_PROCESSING_SERVER = process.env.VUE_APP_IMAGE_SERVER_URL

const pdfIcon = require('@/assets/images/icons/filetype-icons/pdf.png')
const fileIcon = require('@/assets/images/icons/filetype-icons/file.png')

export default {
  components: {
  },
  props: {
    description: {
      type: String,
      required: false,
      default: '',
    },
    extraClass: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    dimensions: {
      type: Object,
      required: false,
      default() {
        return { width: 350, height: 350 }
      },
    },
    imagePublicUrl: {
      type: String,
      required: false,
    },
    fileExtension: {
      type: String,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    gravity: {
      type: String,
      required: false,
      default: 'smart',
    },
    type: {
      type: String,
      required: false,
      default: 'smart',
    },
  },
  data() {
    return {
    }
  },
  computed: {
    imageSource() {
      let iconPath
      switch (this.fileExtension) {
        case 'pdf':
          iconPath = pdfIcon
          break
        default:
          iconPath = fileIcon
      }
      return iconPath
    },
  },
  async mounted() {

  },
  methods: {
    imageClicked() {
      if (this.clickable) {
        this.$emit('file-viewer-clicked', this.imagePublicUrl)
        console.log(this.imagePublicUrl)
      }
    },
  },
}
</script>

<style lang="scss">
.small-thumb {
  width: 100px !important;
  height: 100px !important;
}
.base-image-thumb {
  max-width: 180px;
  max-height: 180px;
  border: 3px solid transparent;
}
.file-thumbnail.selected {
  background-color: #ddd;
  border: 3px solid #ccc;
  padding: 1rem;
}
</style>
