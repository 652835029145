<template>
  <b-modal
    :id="modalId"
    :ref="refId"
    :title="title"
    hide-footer
    size="xl"
  >
    <b-row>
      <b-col>
        <!--        <div class="mb-1">-->
        <!--          <b-button-->
        <!--            class="mr-1"-->
        <!--            @click="listView = true"-->
        <!--          >-->
        <!--            TABLE VIEW-->
        <!--          </b-button>-->
        <!--          <b-button @click="listView = false">-->
        <!--            THUMBNAIL VIEW-->
        <!--          </b-button>-->
        <!--        </div>-->
        <div
          v-if="listView"
          class="table-container"
        >
          <b-table
            selectable
            :fields="merchantImageTableHeader"
            sticky-header="500px"
            class="table-responsive"
            :items="merchantImageTableData"
            @row-selected="onRowSelected"
          >

            <template #cell(filetype)="row">
              {{ get_url_extension(row.item.image_direct_link) }}
            </template>

            <template #cell(thumbnail)="row">
              <template v-if="get_url_extension(row.item.image_direct_link) === 'pdf'">
                pdf
              </template>
              <template v-else>
                <base-image
                  :image-public-url="row.item.image_direct_link"
                  :dimensions="{width: '400', height: '400'}"
                  style="height: 50px; width: 50px;"
                />
              </template>

            </template>
          </b-table>
        </div>
        <div
          v-if="!listView"
          class="thumb-container d-flex flex-wrap bg-light mb-2 p-2"
        >
          <template
            v-for="(fileUrl,index) in merchantImageList"
          >
            <div
              :key="`bimg-${index}`"
              class="lx-thumbnail position-relative"
            >
              <div
                v-if="editable"
                class="lx-thumb-action position-absolute"
              >
                <b-button
                  variant="info"
                  size="sm"
                  @click="preview(fileUrl)"
                >
                  VIEW DETAILS
                </b-button>
              </div>
              <template v-if="fileUrl.file_type === 'pdf'">
                <lp-base-file-viewer
                  :file-extension="fileUrl.file_type"
                  :class="[{'selected': fileUrl.selectedForUse}]"
                  @click.native="select(index,fileUrl)"
                />
              </template>
              <template v-else>
                <lp-base-image
                  :class="[{'selected': fileUrl.selectedForUse}]"
                  :image-public-url="fileUrl.image_direct_link"
                  @click.native="select(index,fileUrl)"
                />
              </template>
            </div>

          </template>
        </div>

        <!--        <div v-if="selected.length > 1">-->
        <!--          Multiple files selected-->
        <!--        </div>-->

        <!--        <div-->
        <!--          v-if="selected.length === 1"-->
        <!--          class="p-1 bg-secondary"-->
        <!--        >-->
        <!--          <b-row>-->
        <!--            <b-col cols="1">-->
        <!--              File Name-->
        <!--            </b-col>-->
        <!--            <b-col cols="3">-->
        <!--              {{ selected[0].file_name }}-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--          <b-row>-->
        <!--            <b-col cols="1">-->
        <!--              File Type-->
        <!--            </b-col>-->
        <!--            <b-col cols="3">-->
        <!--              {{ selected[0].file_type }}-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--          <b-row>-->
        <!--            <b-col cols="1">-->
        <!--              Upload Date-->
        <!--            </b-col>-->
        <!--            <b-col cols="3">-->
        <!--              {{ transformDate(selected[0].upload_date, false, 'FF') }} ({{ transformDate(selected[0].upload_date, true, 'FF') }})-->
        <!--            </b-col>-->
        <!--          </b-row>-->
        <!--        </div>-->

        <!--        <pre>Selected {{ selected }}</pre>-->
        <b-row>
          <b-col>
            <b-form-file
              v-if="multiple"
              v-model="files"
              :accept="allowedFileTypes"
              placeholder="Choose files..."
              no-drop
              multiple
            />
            <b-form-file
              v-if="!multiple"
              v-model="file"
              :accept="allowedFileTypes"
              placeholder="Choose file..."
              no-drop
            />
          </b-col>
          <pre>{{ file }}</pre>
          <b-col cols="auto">
            <b-button
              :disabled="!file"
              class="text-break"
              variant="primary"
              @click="uploadFiles"
            >
              Upload Files
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="previewWindow"
        cols="4"
      >
        <div class="position-relative">
          <b-button
            style="right: 0"
            class="position-absolute"
            @click="closePreview"
          >
            CLOSE
          </b-button>

          <div class="p-1 bg-light">
            <img
              id="galleryPreviewImage"
              class="img-fluid"
              :src="previewData.image_direct_link"
              alt=""
            >
            <div class="my-1">
              <label>File Name</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="previewData.file_name"
              >
            </div>
            <div class="mb-1">
              <label>Description</label>
              <input
                type="text"
                class="form-control"
                disabled
                :value="previewData.description"
              >
            </div>
            <div class="d-flex justify-content-end">

              <b-button class="mr-1">
                EDIT
              </b-button>
              <b-button variant="danger">
                DELETE
              </b-button>

            </div>

          </div>
        </div>
      </b-col>
    </b-row>

    <div class="flex mt-2">
      <b-button @click="selectFiles">
        Select Files
      </b-button>
    </div>

    <pre class="d-none">{{ selected }}</pre>

  </b-modal>
</template>

<script>
import LpBaseImage from '@/leanpay-components/LPBaseImage.vue'
import LpBaseFileViewer from '@/leanpay-components/LPBaseFileViewer.vue'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: {
    LpBaseImage,
    LpBaseFileViewer,
  },
  mixins: [humaniseMixin],
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    allowedFileTypes: {
      type: String,
      default: 'image/*, application/pdf',
    },
    modalId: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    maxFiles: {
      type: Number,
      required: false,
      default: 5,
    },
    refId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      listView: false,
      editable: false,
      file: null,
      selected: [],
      previewData: { },
      previewWindow: false,
      files: [],
      filesUrl: [],
    }
  },
  computed: {
    fileSelectLimit() {
      if (this.multiple) {
        return this.maxFiles
      } return 1
    },
    merchantImageTableData() {
      const list = this.$store.getters['mediaStore/getMerchantImageList']

      return list
    },
    merchantImageTableHeader() {
      const list = [
        {
          key: 'thumbnail', label: 'thumbnail', sortable: false, visible: true, type: 'date',
        },
        {
          key: 'filetype', label: 'File Type', sortable: false, visible: true, type: 'date',
        },
        {
          key: 'file_name', label: 'File Name', sortable: false, visible: true, type: 'string',
        },
        {
          key: 'upload_date', label: 'Date', sortable: false, visible: true, type: 'date',
        },
      ]

      return list
    },
    merchantImageList() {
      const list = this.$store.getters['mediaStore/getMerchantImageList']
      return list
    },
  },
  async mounted() {
    console.log('modal mounted')

    const payload = {
      // start_date: '01-02-2022',
      // end_date: '27-12-2022',
      record_status: 1,
      search: {
        search_enable: false,
        search_key: '',
        search_column: '',
        search_replace_word_enable: true,
        search_word_replace: ' ',
        search_word_replace_to: '_',
      },
      sort: {
        parameter_name: 'created_at',
        sort_type: 'desc',
      },
    }

    await this.$store.dispatch('mediaStore/getMerchantImageListCdn', payload)
  },
  methods: {
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split('.').pop().trim()
    },
    preview(obj) {
      this.previewWindow = true
      this.previewData = obj
      this.getMeta(obj.image_direct_link)
    },
    closePreview() {
      this.previewWindow = false
      this.previewData = null
    },
    getMeta(url) {
      let dimension
      const img = new Image()
      let height
      let width
      img.onload = function () {
        width = this.width
        height = this.height
        console.log(`${width}x${height}`)
        this.previewData.dimensions = `${width}x${height}`
      }
      img.src = url

      // Object.assign(this.previewData, { dimension })

      console.log(this.previewData)
    },
    onRowSelected(items) {
      const selectedItems = items
      console.log(selectedItems)
      const fileUrl = aaa

      if (this.multiple) {
        if (!this.selected.includes(fileUrl)) {
          if (this.selected.length < this.fileSelectLimit) {
            // eslint-disable-next-line no-param-reassign
            fileUrl.selectedForUse = true
            this.selected.push(fileUrl)
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          fileUrl.selectedForUse = false
          const idx = this.selected.indexOf(fileUrl)
          this.selected.splice(idx, 1)
        }
      } else {
        this.selected = []
        this.merchantImageList.forEach(image => {
          // eslint-disable-next-line no-param-reassign
          image.selectedForUse = false
        })
        // eslint-disable-next-line no-param-reassign
        fileUrl.selectedForUse = true
        this.selected.push(fileUrl)
      }
    },
    select(index, fileUrl) {
      console.log(index, fileUrl)
      if (this.multiple) {
        if (!this.selected.includes(fileUrl)) {
          if (this.selected.length < this.fileSelectLimit) {
            // eslint-disable-next-line no-param-reassign
            fileUrl.selectedForUse = true
            this.selected.push(fileUrl)
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          fileUrl.selectedForUse = false
          const idx = this.selected.indexOf(fileUrl)
          this.selected.splice(idx, 1)
        }
      } else {
        this.selected = []
        this.merchantImageList.forEach(image => {
          // eslint-disable-next-line no-param-reassign
          image.selectedForUse = false
        })
        // eslint-disable-next-line no-param-reassign
        fileUrl.selectedForUse = true
        this.selected.push(fileUrl)
      }
    },
    uploadReducer(images) {
      if (images.length > 0) {
        const imageList = images
        const queuedImages = []
        imageList.forEach(image => {
          queuedImages.push(image.image_direct_link)
        })

        return queuedImages
      }
      return null
    },
    selectFiles() {
      const files = this.uploadReducer(this.selected)

      this.$emit('image-selected', files)

      this.$bvModal.hide(`uploadFormModal-${this.fieldName}`)
    },

    uploadFiles() {
      const formData = new FormData()

      if (this.multiple) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.files.length; i++) {
          // formData.append('file', this.files[i])
          formData.append('files', this.files[i])
        }
        this.$store.dispatch('mediaStore/uploadMerchantBulkImageCdn', formData).then(res => {
          if (res.data.response_code === 2000) {
            console.log('data', res.data.data)
            this.filesUrl = res.data.data

            const payload = {
              record_status: 1,
              search: {
                search_enable: false,
                search_key: '',
                search_column: '',
                search_replace_word_enable: true,
                search_word_replace: ' ',
                search_word_replace_to: '_',
              },
              sort: {
                parameter_name: 'created_at',
                sort_type: 'desc',
              },
            }

            this.$store.dispatch('mediaStore/getMerchantImageListCdn', payload)
          }
        })
      } else {
        console.log(this.file)
        console.log(this.file.name)
        formData.append('file', this.file)
        formData.append('file_name', this.file.name)
        console.log(formData)
        this.$store.dispatch('mediaStore/uploadMerchantImageCdn', formData).then(res => {
          if (res.data.response_code === 2000) {
            console.log('data', res.data.data)
            this.filesUrl = res.data.data

            const payload = {
              record_status: 1,
              search: {
                search_enable: false,
                search_key: '',
                search_column: '',
                search_replace_word_enable: true,
                search_word_replace: ' ',
                search_word_replace_to: '_',
              },
              sort: {
                parameter_name: 'created_at',
                sort_type: 'desc',
              },
            }

            this.$store.dispatch('mediaStore/getMerchantImageListCdn', payload)
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.thumb-container {
  max-height: 500px;
  min-height: 500px;
  overflow-y: scroll;
  display: flex;
  img {margin: 0.5rem}
}
.table-container {
}
.lx-thumb-action { display: none}
.lx-thumbnail:hover .lx-thumb-action {
  display: block;
}
.lx-thumbnail> img {
  width: 150px;
  height: 150px;
  margin: 1rem;
}
</style>
