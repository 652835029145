<template>
  <img
    :class="[extraClass, {'cursor-pointer': clickable}]"
    class="base-image-thumb"
    :type="type"
    :dimensions="dimensions"
    :src="imageSource"
    :alt="description"
    :gravity="gravity"
    @click="imageClicked"
  >
</template>

<script>
const IMAGE_PROCESSING_SERVER = process.env.VUE_APP_IMAGE_SERVER_URL

export default {
  components: {
  },
  props: {
    description: {
      type: String,
      required: false,
      default: '',
    },
    extraClass: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    dimensions: {
      type: Object,
      required: false,
      default() {
        return { width: 350, height: 350 }
      },
    },
    imagePublicUrl: {
      type: String,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    gravity: {
      type: String,
      required: false,
      default: 'smart',
    },
    type: {
      type: String,
      required: false,
      default: 'smart',
    },
  },
  data() {
    return {
    }
  },
  computed: {
    imageSource() {
      let image = this.imagePublicUrl
      if (Array.isArray(image)) {
        image = image[0]
        console.log(image)
      }
      if (typeof image === 'string') {
        image = image
      }
      let dimensions
      if (this.dimensions) {
        dimensions = `width=${this.dimensions.width}&height=${this.dimensions.height}`
      }
      return `${IMAGE_PROCESSING_SERVER}/crop?gravity=${this.gravity}&${dimensions}&url=${image}`
    },
  },
  async mounted() {

  },
  methods: {
    imageClicked() {
      if (this.clickable) {
        this.$emit('trigger-clickable', this.imagePublicUrl)
        // alert(this.imagePublicUrl)
      }
    },
  },
}
</script>

<style lang="scss">
.small-thumb {
  width: 100px !important;
  height: 100px !important;
}
.base-image-thumb {
  max-width: 180px;
  max-height: 180px;
  border: 3px solid transparent;
}
.base-image-thumb.selected {
  border: 3px solid red;
}
</style>
